import React, { useState, useRef } from "react";
import "./style.css";
import { Helmet } from "react-helmet";

const SlabaNula = () => {
  const pathName = window.location.pathname;
  let wordAfterSlash = decodeURIComponent(pathName.substring(1));

  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef(null); // Přidáme referenci k input elementu

  const toggleShowInput = () => {
    setShowInput(!showInput);
  };

  // Funkce na kopírování textu do schránky
  const copyToClipboard = () => {
    if (inputRef.current) {
      navigator.clipboard.writeText(
        `www.slabanula.cz/${inputRef.current.value}`
      );
      wordAfterSlash = inputRef.current.value;
      window.location.pathname = inputRef.current.value;
    }
  };

  if (showInput === true) {
    return (
      <div className="slabanulaInputForm">
        <button className="slabanulaButton" onClick={toggleShowInput}>
          Zavřít
        </button>
        <div className="inputdiv">
          <p className="slabanulaTextDescription2">www.slabanula.cz/</p>
          <input
            ref={inputRef} // Přiřadíme referenci
            className="slabanulaInput"
            type="text"
            placeholder={wordAfterSlash ? wordAfterSlash : "Jméno nuly"}
          />
        </div>
        <button className="slabanulaButton" onClick={copyToClipboard}>
          Kopírovat
        </button>{" "}
        {/* Zavoláme funkci pro kopírování */}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content={
            wordAfterSlash
              ? `${wordAfterSlash} JE SLABÁ NULA!`
              : "ŘEKNI NĚKOMU ŽE JE SLABÁ NULA!"
          }
        />
         <title>
     {wordAfterSlash
       ? `${wordAfterSlash} JE SLABÁ NULA!`
       : "ŘEKNI NĚKOMU ŽE JE SLABÁ NULA!"}
   </title>
      </Helmet>
      <div className="slabanula">
        <p />
        <h1 className="slabanulaText">
          {wordAfterSlash
            ? `${wordAfterSlash} je slabá nula!`
            : "Jsi slabá nula!"}
        </h1>
        <p className="slabanulaTextDescription" onClick={toggleShowInput}>
          Řekni někomu že je nula!
        </p>
      </div>
    </>
  );
};

export default SlabaNula;
